import React, { Component } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

class ParkingDetail extends Component {
  render() {
    if (this.props.carparkDetail === null) return;
    return (
      <Offcanvas show={this.props.showDetail} onHide={this.props.onHide} style={{ height: "80%" }} placement={'bottom'}>
        <Offcanvas.Header>
          <Offcanvas.Title>
            <i className="bi bi-geo-alt-fill" /> {this.props.carparkDetail.name}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="container-fluid p-0 m-0">
          <div className="container px-4">
            <h5 className="pt-3">Weekday Peak</h5>
            <div className="fs-6">{this.props.carparkDetail.rate_workday_peak}</div>
            <h5 className="pt-3">Weekday Off-Peak</h5>
            <div className="fs-6">{this.props.carparkDetail.rate_workday_offpeak}</div>
            <h5 className="pt-3">Saturday</h5>
            <div className="fs-6">{this.props.carparkDetail.rate_sat}</div>
            <h5 className="pt-3">Sunday and Public Holiday</h5>
            <div className="fs-6">{this.props.carparkDetail.rate_sun_ph}</div>
          </div>
          <div className="container text-center py-3">
            <Link to="/incorrect-carpark-info">
              <Button variant="outline-secondary px-5">Report Incorrect Information</Button>
            </Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
}

export default ParkingDetail;