import React, { Component } from 'react';
import { Button } from "react-bootstrap";

class ParkingPeriod extends Component {

  constructor() {
    super();
    this.buttons = [
      'btn-warning',
      'btn-secondary',
      'btn-secondary',
      'btn-secondary',
      'btn-secondary'
    ]
  }

  onClickPeriod = (btnIndex, parkingPeriod) => {
    for (let i = 0; i < this.buttons.length; ++i) {
      if (i === btnIndex) {
        this.buttons[i] = 'btn-warning';
      } else {
        this.buttons[i] = 'btn-secondary';
      }
    }
    this.props.onChangeParkingPeriod(parkingPeriod);
  }

  render() {
    if(this.props.destinationAddr === "") return;
    return (
      <div className="input-group pt-1 pb-2">
        <Button className={"btn fs-5 btn-secondary"}>for</Button>
        <Button className={"btn fs-5 " + this.buttons[0]} onClick={() => this.onClickPeriod(0, 0.5)}>30min</Button>
        <Button className={"btn fs-5 " + this.buttons[1]} onClick={() => this.onClickPeriod(1, 1)}>1hr</Button>
        <Button className={"btn fs-5 " + this.buttons[2]} onClick={() => this.onClickPeriod(2, 2)}>2hr</Button>
        <Button className={"btn fs-5 " + this.buttons[3]} onClick={() => this.onClickPeriod(3, 3)}>3hr</Button>
        <Button className={"btn fs-5 " + this.buttons[4]} onClick={() => this.onClickPeriod(4, 6)}>6hr</Button>
      </div>
    );
  }
}

export default ParkingPeriod;