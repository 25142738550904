export class CarparkAvailability {
  constructor(callback) {
    this.data = null;
    this.refreshDateTime = null;
    this.dataError = null;
    this.dataLoad = false;
    this.callback = callback;

    this.fetchAvailabilityData();
  }

  fetchAvailabilityData = () => {
    const url = 'https://www.koleksikatik.com/data/d.json';
    const method = 'GET';
    const headers = { 'content-type': 'application/json' };

    fetch(url, { method: method, headers })
      .then(response => { return response.json() })
      .then((data) => {
        this.data = data.items[0].carpark_data;
        this.refreshDateTime = data.items[0].timestamp;
        this.dataLoad = true;
        this.callback();
      }).catch((error) => {
        this.dataError = error;
        this.dataLoad = true;
        this.callback();
      });
  }

  populateAvailability = (shortlists) => {
    if (this.data === undefined || this.data === null) return;
    shortlists.forEach(shortlist => {
      const carparkNo = shortlist.carpark.car_park_no;
      this.data.forEach(availability => {
        const availabilityCarparkNo = availability.carpark_number;
        if (carparkNo === availabilityCarparkNo) {
          shortlist.carpark.carparkAvailability = availability.carpark_info;
        }
      })
    });
  }
}
