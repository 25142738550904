import React, { useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { InputGroup, Form } from "react-bootstrap";

const PlacesAutocomplete = (props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: { country: 'sg', },
    },
    debounce: 300,
  });

  useEffect (() => {
    // NOTE: this is default logic to load the current location
    const initLoad = props.inputs.initLoad;
    const originLat = props.inputs.originLat;
    const originLng = props.inputs.originLng;
    const originAdd = originLat + "," + originLng;
    if(initLoad === undefined || initLoad === false) return; // NOTE: to make sure that this procedure is only called once
    if(originLat === undefined || originLng === undefined || originLat === 0 || originLng === 0) return;
    props.completeInitLoad();
    const parameter = { address: originAdd, };
    getGeocode(parameter)
      .then((results) => {
        const address = results[0].formatted_address;
        setValue(address, false);
        clearSuggestions();
        props.onSelectDestination({ destinationAddr: address, destinationLat: originLat, destinationLng: originLng });
      });
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = (suggestion) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      const fullAddress = suggestion.description;
      const placeName = suggestion.structured_formatting.main_text;
      const address = suggestion.structured_formatting.secondary_text;
      setValue(placeName + ", " + address, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: fullAddress })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          //console.log("Coordinates: ", { lat, lng });
          props.onSelectDestination({ destinationAddr: address, destinationLat: lat, destinationLng: lng });
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li className="dropdown-item" key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref}>
      <InputGroup className="mb-1">
        <Form.Control
          type="search"
          className="fs-5"
          placeholder="Destination"
          aria-label="Destination"
          aria-describedby="basic-addon2"
          onChange={handleInput}
          disabled={!ready}
          value={value} />
      </InputGroup>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <div className="dropdown"><ul className="dropdown-menu d-block fs-5">{renderSuggestions()}</ul></div>}
    </div>
  );
};

export default PlacesAutocomplete;

