import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "",
  authDomain: "nearest-parking-58a90.firebaseapp.com",
  projectId: "nearest-parking-58a90",
  storageBucket: "nearest-parking-58a90.appspot.com",
  messagingSenderId: "543236969803",
  appId: "1:543236969803:web:73756cbeadc9e65c4a44ce",
  measurementId: "G-P6E5LH4QY0"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const getNearestCarparks = httpsCallable(functions, 'getNearestCarparks');

export default getNearestCarparks;
