import React from "react";
import { Helmet } from "react-helmet";
import PlacesAutocomplete from "./PlacesAutocomplete";
import DistanceMatrixWrapper from "./DistanceMatrixWrapper";
import ParkingPeriod from "./ParkingPeriod";
import ParkingTimeOffset from "./ParkingTimeOffset";
import ParkingList from "./ParkingList";
import ParkingDetail from "./ParkingDetail";
import { CarparkAvailability } from "../lib/CarparkAvailability";
import getNearestCarparks from "../lib/NearestFirebase";


class AppMain extends React.Component {
  state = {
    position: null,
    distance: null,
    showDetail: false,
    offsetOverride: false,
    calculateDistance: false,
    initLoad: false,
    originLat: 0,
    originLng: 0,
    destinationAddr: "",
    destinationLat: 0,
    destinationLng: 0,
    destinationSearchRadius: 1,
    timeToDestination: 0,
    parkingPeriod: 0.5,
    carparkDetail: null,
    shortlists: []
  };

  componentDidMount = () => {
    this.showLoading(true);
    navigator.geolocation.getCurrentPosition(
      this.onPositionSuccess,
      this.onPositionError,
      {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 5000
      }
    );
    this.carparkAvailability = new CarparkAvailability(() => { /*this.showLoading(false)*/ });
  }

  onPositionSuccess = (position) => {
    const originLat = position.coords.latitude;
    const originLng = position.coords.longitude;
    const initLoad = true;
    this.setState({ position, originLat, originLng, initLoad });
    this.showLoading(false);
  }

  onPositionError = (error) => {
    // TODO: improvement on how to warn customers
    this.showLoading(false);
  }

  completeInitLoad = () => {
    const initLoad = false;
    this.setState({ initLoad });
  }

  refreshCarparkShortlist = () => {
    if (this.state.destinationAddr === "") return;
    this.showLoading(true);
    const oLat = (this.state.originLat === 0) ? this.state.destinationLat : this.state.originLat;
    const oLng = (this.state.originLng === 0) ? this.state.destinationLng : this.state.originLng;
    getNearestCarparks({
      oLat: oLat,
      oLng: oLng,
      dLat: this.state.destinationLat,
      dLng: this.state.destinationLng,
      timeToDest: this.state.timeToDestination,
      parkPeriod: this.state.parkingPeriod,
      destRadius: this.state.destinationSearchRadius,
    }).then((result) => {
      const shortlists = result.data;
      //console.log(result);
      this.carparkAvailability.populateAvailability(shortlists);
      this.setState({ shortlists: shortlists }, () => {
        //console.log(this.state);
        this.showLoading(false);
      });
    });
  }

  showLoading = (isLoading) => {
    const display = (isLoading) ? "flex" : "none";
    document.getElementById("overlay").style.display = display;
  }

  onSelectDestination = (destinationData) => {
    this.setState({ offsetOverride: false, calculateDistance: true });
    this.setState(destinationData, this.refreshCarparkShortlist);
  }

  onChangeParkingPeriod = (parkingPeriod) => {
    this.setState({ parkingPeriod: parkingPeriod }, this.refreshCarparkShortlist);
  }

  onChangeOffset = (event) => {
    this.setState({ offsetOverride: true, timeToDestination: event.target.value }, this.refreshCarparkShortlist);
  }

  onChangeDMDistance = (distance) => {
    this.setState({ distance: distance });
  }

  onChangeDMOffset = (offset) => {
    if (this.state.offsetOverride === true) return; // NOTE: when will offset be override
    this.setState({ timeToDestination: offset, calculateDistance: false });
  }

  onShowDetail = (detailIndex) => {
    const carparkDetail = this.state.shortlists[detailIndex].carpark;
    this.setState({ carparkDetail: carparkDetail, showDetail: true });
  }

  onCloseShowDetail = () => {
    this.setState({ showDetail: false });
  }

  render() {
    return (
      <main>
        <Helmet>
          <title>Parkingly.co | Parking Fee in Singapore</title>
          <meta name="description" content="Find parking fee and nearest carparks in Singapore" />
          <link rel="canonical" href="https://www.parkingly.co/" />
        </Helmet>
        <div id="overlay" style={{ display: "flex", }}>
          <div className="w-100 d-flex justify-content-center align-items-center">
            <div className="spinner"></div>
          </div>
        </div>
        <div className="container-fluid bg-secondary p-0 m-0 pb-2">
          <div className="container">
            <PlacesAutocomplete
              inputs={this.state}
              onSelectDestination={this.onSelectDestination}
              completeInitLoad={this.completeInitLoad} />
            <DistanceMatrixWrapper
              inputs={this.state}
              onChangeDMDistance={this.onChangeDMDistance}
              onChangeDMOffset={this.onChangeDMOffset} />
          </div>
        </div>
        <div className="container-fluid p-0 m-0">
          <div className="container-lg py-2">
            <ParkingTimeOffset
              destinationAddr={this.state.destinationAddr}
              offset={this.state.timeToDestination}
              onChangeOffset={this.onChangeOffset} />
            <ParkingPeriod
              destinationAddr={this.state.destinationAddr}
              onChangeParkingPeriod={this.onChangeParkingPeriod} />
          </div>
          <ParkingList show={this.onShowDetail} shortlists={this.state.shortlists} />
        </div>
        <ParkingDetail showDetail={this.state.showDetail} onHide={this.onCloseShowDetail} carparkDetail={this.state.carparkDetail} />
      </main>
    );
  }
}

export default AppMain;

