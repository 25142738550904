import React, { Component } from 'react';
import { DistanceMatrixService } from '@react-google-maps/api';

class DistanceMatrixWrapper extends Component {
  render() {
    if (this.props.inputs.calculateDistance === false) {
      //console.log("DO NOT DO!");
      return;
    } else {
      //console.log("LET'S GO!");
    }
    const travelMode = 'DRIVING';
    const position = this.props.inputs.position;
    const destAdr = this.props.inputs.destinationAddr;
    const offsetOverride = this.props.inputs.offsetOverride;
    const calculateDistance = this.props.inputs.calculateDistance;
    const origLat = (this.props.inputs.position !== null) ? this.props.inputs.position.coords.latitude : 1.2857166;
    const origLng = (this.props.inputs.position !== null) ? this.props.inputs.position.coords.longitude : 103.8235629;
    const destLat = this.props.inputs.destinationLat;
    const destLng = this.props.inputs.destinationLng;
    return (
      <DistanceMatrixService
        options={{
          destinations: [{ lat: origLat, lng: origLng }],
          origins: [{ lat: destLat, lng: destLng }],
          travelMode: travelMode,
        }}
        callback={(res) => {
          if (position !== null) {
            let distance = res.rows[0].elements[0];
            this.props.onChangeDMDistance(distance);
            if (destAdr !== "" && offsetOverride === false) {
              let duration = distance.duration.value / 60;
              let offset = Math.ceil(duration / 5) * 5;
              this.props.onChangeDMOffset(offset);
            }
          } else {
            if (calculateDistance) {
              const offset = 0;
              this.props.onChangeDMOffset(offset);
            }
          }
        }}
      />
    );
  }
}

export default DistanceMatrixWrapper;