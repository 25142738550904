import React, { Component } from 'react';

class ParkingTimeOffset extends Component {

  constructor() {
    super();
    this.offsets = [
      { value: 0, description: 'parking start now, at ' },
      { value: 5, description: 'parking start in +5min, at ' },
      { value: 10, description: 'parking start in +10min, at ' },
      { value: 15, description: 'parking start in +15min, at ' },
      { value: 20, description: 'parking start in +20min, at ' },
      { value: 25, description: 'parking start in +25min, at ' },
      { value: 30, description: 'parking start in +30min, at ' },
      { value: 35, description: 'parking start in +35min, at ' },
      { value: 40, description: 'parking start in +40min, at ' },
      { value: 45, description: 'parking start in +45min, at ' }
    ];
  }

  refreshOffsets = () => {
    const dateTimeNow = Date.now();
    this.offsets.forEach(offset => {
      const offsetDateTime = new Date(dateTimeNow + offset.value * 60 * 1000);
      const offsetHours = offsetDateTime.getHours().toString().padStart(2, '0');
      const offsetMinutes = offsetDateTime.getMinutes().toString().padStart(2, '0');
      const timeDescription = offsetHours + ":" + offsetMinutes;
      offset.timeDescription = timeDescription;
    });
  }

  render() {
    if(this.props.destinationAddr === "") return;
    this.refreshOffsets();
    return (
      <div className="col-xs-2">
        <select className="form-select fs-5 bg-warning" style={{ width: "347px" }} value={this.props.offset} onChange={this.props.onChangeOffset}>
          {this.offsets.map(offset => (
            <option key={offset.value} value={offset.value}>{offset.description}{offset.timeDescription}</option>))}
        </select>
      </div>
    );
  }
}

export default ParkingTimeOffset;