import React, { Component } from 'react';
import ParkingListItem from './ParkingListItem';
import Button from 'react-bootstrap/Button';

class ParkingList extends Component {
  state = {
    itemsToShow: 5
  };

  populateListItems = () => {
    const listItems = [];
    const shortlistsLength = this.props.shortlists.length;
    const itemsToShow = (shortlistsLength > this.state.itemsToShow) ? this.state.itemsToShow : shortlistsLength;
    for (let i=0; i<itemsToShow; ++i) {
      const show = this.props.show;
      const carparkData = this.props.shortlists[i];
      listItems.push(<ParkingListItem show={show} key={i} detailIndex={i} carparkData={carparkData} />);
    }
    return listItems;
  }

  prepareShowMoreButton = () => {
    if (this.state.itemsToShow < this.props.shortlists.length) {
      return (
        <div className="text-center my-3">
          <Button variant="outline-secondary px-5" onClick={this.onClickShowMore}>Show More Carparks...</Button>
        </div>
      );
    } else {
      return (<div></div>);
    }
  }

  onClickShowMore = () => {
    const shortlistsLength = this.props.shortlists.length;
    if(shortlistsLength > this.state.itemsToShow) {
      const itemsToShow = this.state.itemsToShow + 5;
      this.setState({ itemsToShow });
    }
  }

  render() {
    if (this.props.shortlists.length < 1) return;
    const listItems = this.populateListItems();
    const showMoreButton = this.prepareShowMoreButton();
    return (
      <div className="container-lg px-0 pb-2">
        <ul className="list-group list-group-flush fs-5">
          {listItems}
        </ul>
        {showMoreButton}
      </div>
    );
  }
}

export default ParkingList;