import React, { Component } from 'react';
import { Helmet } from "react-helmet";

class WhyParkingly extends Component {

  render() {
    return (
      <main>
        <Helmet>
          <title>Parkingly.co | Just Parking, No Nonsense</title>
          <meta name="description" content="Parkingly.co is a strip-down app to quickly find parking in Singapore. A simple app, not a super app." />
          <link rel="canonical" href="https://www.parkingly.co/just-parking-why-parkingly" />
        </Helmet>
        <div className="container-fluid p-0 m-0">
          <div className="container-lg pt-4">
            <h3 className="py-2">Just Parking, No Nonsense</h3>
            <p>Decides your carpark quickly with minimal actions and quickly drive to your destination. A simple app, not a super app. Parkingly is for you who like simplicity in life.</p>
            <h4 className="py-2">1. Find nearby carparks or based on your destination</h4>
            <p>Parkingly automatically finds nearby carparks on your location (if location service is enabled). Or, you can change for a new list of carparks at your destination.</p>
            <h4 className="py-2">2. Estimate parking fee based on your arrival to destination</h4>
            <p>Parkingly automatically estimates your arrival time and uses it to estimate your carpark fee. Change your arrival time and parking period to estimate your parking fee.</p>
            <h4 className="py-2">3. Use your favorite routing app (update soon)</h4>
            <p>Select one of the listed carparks to get into Google Map App to drive from your current location to destination. We are enabling Apple Map and Waze App in the future.</p>
          </div>
        </div>
      </main>
    );
  }

}

export default WhyParkingly;
