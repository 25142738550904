import React from "react";
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';

import AppMain from "./components/AppMain";
import TermsOfUse from "./components/TermsOfUse";
import WhyParkingly from "./components/WhyParkingly";

import "./App.css";

const ContactUsPage = () => {
  useEffect(()  => {
    window.location.replace('https://forms.gle/vfqyaVF4LZrtiwuEA');
  }, [])

  return (
    <div className="container-fluid p-0 m-0">
      <div className="container-lg pt-4">
        <h3 className="py-2">Feedback on carpark data, redirecting...</h3>
      </div>
    </div>);
}

class App extends React.Component {
  state = {
    showMenu: false
  };

  onToggleShowMenu = () => {
    if(this.state.showMenu) {
      this.setState({ showMenu: false });
    } else {
      this.setState({ showMenu: true });
    }
  }

  render() {
    return (
      <Router>
        <Offcanvas show={this.state.showMenu} onHide={this.onToggleShowMenu} style={{ width: "300px" }} placement={'end'}>
          <Offcanvas.Header>
            <Offcanvas.Title className="p-3">Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="list-group list-group-flush p-0 m-0">
              <li className="list-group-item"><Link to="/">Parkingly App</Link></li>
              <li className="list-group-item"><Link to="/just-parking-why-parkingly">Just Parking, No Nonsense</Link></li>
              <li className="list-group-item"><Link to="/missing-carpark">Report Missing Carpark</Link></li>
              <li className="list-group-item"><Link to="/terms-of-use">Terms of Use</Link></li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
        <div className="navbar navbar-dark bg-secondary container-fluid p-0 pt-4 pb-2">
          <div className="container d-flex justify-content-between">
            <h1 className="text-light">
              <span className="fw-bold">Parkingly</span>.co
            </h1>
            <button className="btn btn-outline-light mb-3" onClick={this.onToggleShowMenu}>
              <i className="bi bi-list fs-5"></i>
            </button>
          </div>
        </div>
        <Routes>
          <Route path='/' element={<AppMain />} />
          <Route path='/terms-of-use' element={<TermsOfUse />} />
          <Route path='/missing-carpark' element={<ContactUsPage />} />
          <Route path='/incorrect-carpark-info' element={<ContactUsPage />} />
          <Route path='/just-parking-why-parkingly' element={<WhyParkingly />} />
        </Routes>
      </Router>
    );
  }
}

export default App;

