import React, { Component } from 'react';

class ParkingListItem extends Component {

  onClickDestination = () => {
    const googleMapUrl = this.props.carparkData.googleMapUrl;
    window.open(googleMapUrl, '_blank');
  }

  renderParkingFee = (estimate) => {
    if (estimate.parkingForbidden === true) {
      return <div className="badge bg-danger">CLOSED</div>;
    } else {
      if (estimate.parkingFee === 0) {
        return <div className="badge bg-primary">FREE</div>;
      } else {
        return <div className="badge bg-primary">S${estimate.parkingFee.toFixed(2)}</div>;
      }
    }
  }

  renderAvailability = (availability) => {
    if (availability === undefined) return;
    const lots_available = availability[0].lots_available;
    if (lots_available !== "0") {
      return <div className="badge bg-primary">{lots_available} lots</div>;
    } else {
      return <div className="badge bg-danger">{lots_available} lots</div>;
    }
  }

  render() {
    if (this.props.carparkData === null) return;
    const availability = this.props.carparkData.carpark.carparkAvailability;
    const estimate = {
      parkingFee: this.props.carparkData.fee_structure.parkingFee,
      parkingForbidden: this.props.carparkData.fee_structure.parkingForbidden
    };
    return (
      <li
        variant="light"
        className="list-group-item list-group-item-action align-items-start d-flex px-0">

        <div className="col-2 col-sm-1 text-center" onClick={() => this.props.show(this.props.detailIndex)}>
          <i className="bi bi-geo-alt-fill fs-3"></i>
        </div>
        <div className="col-7 col-sm-8 fs-6" onClick={this.onClickDestination}>
          <div className="fw-bold">{this.props.carparkData.carpark.name}</div>
          <div>{this.props.carparkData.walkingMinutes} min walk</div>
          <div>{this.props.carparkData.carpark.address}</div>
        </div>
        <div className="col-3 text-end p-0 pe-2">
          {this.renderParkingFee(estimate)}
          {this.renderAvailability(availability)}
        </div>

      </li>
    );
  }
}

export default ParkingListItem;

